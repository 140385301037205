import React from "react"
import { Grid, Box, Flex, Text } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/Breadcrumbs"
import NewsletterSignup from "../components/NewsletterSignup"

const NewsletterPage = () => (
  <Layout>
    <SEO title="Newsletter" />
    
    {/* <Breadcrumbs title="Newsletter" /> */}
    
    <Grid variant="base">
      <Box sx={{ bg: "background", p: [3, 5], maxWidth: 800, mx: "auto" }}>
        <NewsletterSignup />
      </Box>
    </Grid>
  </Layout>
)

export default NewsletterPage
